// @import 'bootstrap/scss/_variables.scss';

// COLORS

$dark-purple: #4a148c;
$grey: #fff;
$light-orange: #ffa726;
$dark-orange: #fb8c00;
$pink: #f48fb1;
$amber: #ffd740;
$purple: #8e24aa;
$light-green: #9ccc65;
$light-blue: #6495ed;
$white: #fff;
$black: #000;
$deep-red: #9e2424;
$light-yellow: #ffe699;
$liberal-green: #8ab6a7;
$fascist-orange: #ec6449;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-serif: 'Vollkorn', serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
  monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$primary: $light-blue;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// $theme-colors: () !default;
// // stylelint-disable-next-line scss/dollar-variable-default
// $theme-colors: map-merge(
//   (
//     "primary":    $primary,
//     "secondary":  $secondary,
//     "success":    $success,
//     "info":       $info,
//     "warning":    $warning,
//     "danger":     $danger,
//     "light":      $light,
//     "dark":       $dark
//   ),
//   $theme-colors
// );
